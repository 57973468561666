(function () {
  // Add a body class once page has loaded
  // Used to add CSS transitions to elems
  // and avoids content shifting during page load
  window.addEventListener('load', function () {
    document.body.classList.add('page-loaded');
  });

})();


const button = document.querySelector('.back-to-top');

const displayButton = () => {
  window.addEventListener('scroll', () => {
    if (window.scrollY > 100) {
      button.style.display = "block";
    } else {
      button.style.display = "none";
    }
  });
};

const scrollToTop = () => {
  button.addEventListener("click", () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  });
};

displayButton();
scrollToTop();

// counter

function animateValue(obj, start, end, duration) {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    obj.innerHTML = Math.floor(progress * (end - start) + start);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}
let hasScrolled = false;
let hasScrolled2 = false;
const scrollHandler = () => {
  const triggerPoint0 = 0;
  const triggerPoint = 2900;
  if (window.scrollY > triggerPoint) {
    if (hasScrolled) return;

    const obj = document.getElementById("counter-year");
    if (!obj) return;
    animateValue(obj, 0, 50, 1600);

    hasScrolled = true; // Flag'ı değiştir
  }

  if (window.scrollY > triggerPoint0) {
    if (hasScrolled2) return;

    const obj2 = document.getElementById("since-counter");
    if (!obj2) return;
    animateValue(obj2, 0, 1974, 1500);
    
    const obj3 = document.getElementById("project-counter");
    if (!obj3) return;
    animateValue(obj3, 0, 300, 1500);

    const obj4 = document.getElementById("client-counter");
    if (!obj4) return;
    animateValue(obj4, 0, 97, 1500);

    const obj5 = document.getElementById("country-counter");
    if (!obj5) return;
    animateValue(obj5, 0, 5, 1500);
    
    hasScrolled2 = true; // Flag'ı değiştir
  }
};

window.addEventListener("scroll", scrollHandler);

// scroll to down
document.addEventListener("DOMContentLoaded", function() {
  const scrollLinks = document.querySelectorAll('.scroll-link');
  
  scrollLinks.forEach(link => {
    link.addEventListener('click', function(e) {
      e.preventDefault();
      const targetId = this.getAttribute('href');
      const targetElement = document.querySelector(targetId);
      const navbarHeight = document.querySelector('nav').offsetHeight; // Adjust if your navbar selector is different
      const targetPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition = targetPosition + window.pageYOffset - navbarHeight;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    });
  });
});