function adjustMainNavbarMargin() {
  var mainNavbar = document.querySelector('.navbar');
  var isMobile = window.innerWidth <= 992;

  if (!isMobile) {
      if (window.scrollY >= 300) {
          mainNavbar.style.marginTop = '0px';
      } else {
          mainNavbar.style.marginTop = '46px';
      }
  } else {
      // No margin-top for mobile screens
      mainNavbar.style.marginTop = '0px';
  }
}

// Listen for scroll events
window.addEventListener('scroll', function () {
  var navbar = document.querySelector('.fixed-top');

  if (this.window.scrollY >= 300) {
      document.body.classList.add("is-scroll");
  } else {
      document.body.classList.remove("is-scroll");
  }

  if (window.scrollY > 300 || window.scrollY === 0) {
      navbar.classList.add('shadow-sm');
      navbar.style.top = '0px';
  } else {
      navbar.classList.remove('shadow-sm');
      navbar.style.top = '-200px';
  }

  adjustMainNavbarMargin();
});

window.addEventListener('resize', adjustMainNavbarMargin);

document.addEventListener('DOMContentLoaded', adjustMainNavbarMargin);

(function () {
    const megaMenuToggles = document.querySelectorAll('.nav-item.dropdown') || [];
    function showMenu(event) {
      console.log("show", event.target)
      const dropdown = event.target.querySelector('.dropdown-menu');
      const menuToggle = event.target.querySelector('.dropdown-toggle');
  
      if (dropdown && menuToggle) {
        dropdown.classList.add('show');
        menuToggle.setAttribute('aria-expanded', 'true');
        menuToggle.classList.add('show');
      }
    }
  
    function hideMenu(event) {
      console.log("hide", event.target)
      const dropdown = event.target.querySelector('.dropdown-menu');
      const menuToggle = event.target.querySelector('.dropdown-toggle');
  
      if (dropdown && menuToggle) {
          dropdown.classList.remove('show');
          menuToggle.setAttribute('aria-expanded', 'false');
          menuToggle.classList.remove('show');
      }
  }
  
    megaMenuToggles.forEach((toggle) => {
      toggle.addEventListener('mouseenter', (event) => {
        showMenu(event);
      });
      toggle.addEventListener('mouseleave', (event) => {
        hideMenu(event);
      });
    });
  })();