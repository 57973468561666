import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger) 

let tl = gsap.timeline({
  scrollTrigger: {
    trigger: ".bg-asd",
    start: "top-=400 center",
    end: "+=200",
    scrub: 1.5,
    markers: false,
  }
}).fromTo(".bg-asd", { width: "30%" }, { width: "100%"});

let tl2 = gsap.timeline({
  scrollTrigger: {
    trigger: ".product-title",
    start: "top center",
    end: "+=200",
    scrub: 1.5,
    markers: false
  },
}).fromTo(".product-title", { x: 50}, { x: 0});