import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Thumbs, EffectFade } from 'swiper/modules';

(function () {
  const swipers = document.querySelectorAll('[data-swiper]');

  swipers.forEach((swiper) => {
    let options = swiper.dataset.swiper ? JSON.parse(swiper.dataset.swiper) : {};

    // init Swiper:
    new Swiper(swiper, {
      modules: [Navigation, Pagination, Autoplay, EffectFade],
      ...options,
    });
  })
})();

Swiper.use([Navigation, Thumbs, EffectFade, Autoplay]);

var swiper = new Swiper(".mySwiper", {
  watchSlidesProgress: true,
  speed: 3000,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  slidesPerView: 1
});

var swiper2 = new Swiper(".mySwiper2", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  speed: 2000,
  slidesPerView: 1,
  autoplay: {
    delay: 2000,
  },
  centeredSlides: true,
  grabCursor: true,
  spaceBetween: "15",
  loop: false,
  thumbs: {
    swiper: swiper,
  },

  breakpoints: {
    1000: {
      slidesPerView: 2,
    },

    1530: {
      slidesPerView: 4,
    }
  },
});


var swiper3 = new Swiper(".mySwiper3", {
  allowTouchMove: false,
  speed: 2000,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  slidesPerView: 1,
});

var swiper4 = new Swiper(".mySwiper4", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  centeredSlides: true,
  grabCursor: true,
  speed: 2000,
  slidesPerView: 1,
  spaceBetween: "15",
  thumbs: {
    swiper: swiper3,
  },

  breakpoints: {
    300: {
      slidesPerView: 2,
    },

    1530: {
      slidesPerView: 4,
    }
  },
});

var swiper5 = new Swiper(".mySwiper5", {
  pagination: {
    el: ".swiper-pagination",
  },
  speed: 2000,
  slidesPerView: 1,
  allowTouchMove: false,
});

var swiper6 = new Swiper(".mySwiper6", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  centeredSlides: true,
  grabCursor: true,
  speed: 2000,
  slidesPerView: 1,
  spaceBetween: "50",
  thumbs: {
    swiper: swiper5,
  },

  breakpoints: {
    300: {
      slidesPerView: 4,
    },
  },
});

var swiper7 = new Swiper(".mySwiper7", {
  cssMode: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
  },
  speed: 2000,
  slidesPerView: 1,
});

var swiper8 = new Swiper(".mySwiper8", {
  slidesPerView: 1,
  spaceBetween: 30,
  grabCursort: true,
  autoplay: {
    delay: 2000,
  },
  loop: true,
  breakpoints: {
    768: {
      slidesPerView: 2, 
    },
    992: {
      slidesPerView: 4,
    },
  },
});




