import AOS from 'aos';

(function () {
  
  const options = {
    duration: 300,
    easing: 'ease-in',
    once: false,
    startEvent: 'load',
    disable: 'mobile'
  };

  AOS.init(options);

})();