import { Fancybox } from "@fancyapps/ui";

Fancybox.bind('[data-fancybox="gallery"]', {
  // Your custom options for a specific gallery
});

Fancybox.bind('[data-fancybox="product-detail-1"]', {
  // Your custom options for a specific gallery
});

Fancybox.bind('[data-fancybox="product-detail-2"]', {
  // Your custom options for a specific gallery
});